@import 'variables';

.swal-no-icon-border {
    border: 0 !important;
    font-size: 10px;
}

.swal-title {
    padding-top: 0;
    font-size: 18px;
    padding-bottom: 0.5rem;
    margin-top: 1.25rem;
    color: $colorPrimary800;

    &.title-delete {
        color: $colorError700;
    }
}

.swal-close-button {
    margin: 20px 20px -30px 0;
    padding: 0;
    height: 20px;
    width: 20px;
    font-size: 28px;
    color: $colorGray500;
}

.swal-cancel-button {
    color: $colorGray700 !important;
    font-weight: 600;
    background-color: white !important;
    border: 1px solid $colorGray300 !important;
    width: 16rem;
    font-size: 14px !important;
    padding: 0.63rem 1.13rem;
    border-radius: 8px !important;
}

.swal-confirm-button {
    font-weight: 600;
    width: 16rem;
    font-size: 14px !important;
    padding: 0.63rem 1.13rem;
    border-radius: 8px !important;
}

.swal-actions {
    padding: 0.5rem 0.8rem 0 0.8rem;
    justify-content: center;
    width: 100%;
}

.swal-container {
    color: $colorGray600 !important;
    font-size: 14px !important;
}

.swal-popup {
    border-radius: 1.875rem;
    width: 900px;
}

.swal2-popup {
    display: flex;
    flex-direction: column;
    gap: 30px;

    padding: 20px;
    margin: 0;

    button.swal2-close.swal-close-button {
        margin: 0;
        position: absolute;
        right: 20px;
        top: 20px;
    }

    h2.swal2-title.swal-title {
        padding: 0;
        margin: 0;
    }

    div.swal2-html-container.swal-container {
        padding: 0;
        margin: 0;
    }

    div.swal2-actions.swal-actions {
        padding: 0;
        margin: 0;

        button {
            margin-top: 0;
            margin-bottom: 0;
        }
    }
}
