@import 'node_modules/bootstrap/scss/functions';
@import 'bootstrap/scss/_variables';
@import './colors.scss';

// Here you can set the global theme colors used by Bootstrap 5
$theme-colors: (
    'primary': $colorCyan600,
    'secondary': $secondary,
    'success': $success,
    'info': $info,
    'warning': $warning,
    'danger': $danger,
    'light': $light,
    'dark': $dark,
);

//TODO : this file should be the interface between all SCSS file and variables in ./color.scss.
//For exemple : $inputFocusedColor is the color use on all focused input : we set the color here, using a variable from color.scss
//All scss files should not use variables from colors.scss

// Input
$inputFocusedColor: $colorCyan300;
$inputErrorColor: $colorError300;
//$inputErrorTextColor: $colorError500;

// Layer
$largeLayerTitleFontSize: 20px;
$layerTitleFontSize: 18px;
//$layerDescFontSize: 16px;
//$layerDescMarginTop: 8px;
$layerDescMarginBottom: 12px;
//$layerActionsMarginTop: 16px;

//Modal :
$page-padding: 32px;

//SideBar
$sidenavWidth: 176px;
$sidenavLeftRightPadding: 12px;

//Header 
$headerHeight: 60px;
$headerCircleColor: #ecb467;
$headerCircleBorderColor: #dcdcdc;

//Public Pages :
$publicLeftColor: #d4d4d4;
$publicRightColor: #ebebeb;


$colorAec1BlueLight: #5881C1;  
$colorAec2BlueDark: #183E7A;   
$colorAec3Green: #84BCAF;        
$colorAec4Orange: #F8AE53;       
$colorAec5Brown: #C87110;        
$colorAec6Gray: #AAA09E;         
$colorAec7RedLight: #E9564C;     
$colorAec8RedDark: #B41405;      
$colorAec9Cream: #F2EEEC; 
