.modal-content {
    display: flex;
    flex-direction: column;
    gap: 30px;

    .modal-content-header {
        h1 {
            font-size: $layerTitleFontSize;
            font-weight: 700;
            color: $colorPrimary800;
            margin-bottom: 0;
            text-align: center;
        }
    }

    .modal-content-body {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .modal-content-footer {
        display: flex;
        justify-content: center;

        ed-button {
            min-width: 250px;
        }

        ed-button:not(:last-child) {
            margin-right: 10px;
        }
    }
}

.horizontal-form {
    display: flex;
    width: 100%;
    gap: 10px;

    > div {
        flex-basis: 50%;
    }
}
