@import '../scss/variables.scss';

$tab-padding-top: 1px;
$tab-padding-right: 4px;
$tab-padding-bottom: 5px;
$tab-padding-left: 4px;

$tab-border-bottom-width: 2px;

.settings-menu {
    display: flex;
    gap: 16px;
    color: $colorGray500;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 10px;

    .settings-item {
        font-weight: 600;
        color: $colorGray500;
        cursor: pointer;
        text-decoration: none;

        padding: $tab-padding-top $tab-padding-right $tab-padding-bottom $tab-padding-left;

        &:hover {
            color: $colorGray600;
        }

        &.selected {
            color: $colorAec700;
            border-bottom: $tab-border-bottom-width solid $colorAec700;
            padding-bottom: calc($tab-padding-bottom - $tab-border-bottom-width);
        }
    }
}
