/******* Primary Colors from UntitledUI *******/

$white: #FFFFFF !default;
$black: #000000 !default;

$colorGray25: #FCFCFD !default;
$colorGray50: #F9FAFB !default;
$colorGray100: #F2F4F7 !default;
$colorGray200: #EAECF0 !default;
$colorGray300: #D0D5DD !default;
$colorGray400: #98A2B3 !default;
$colorGray500: #667085 !default;
$colorGray600: #475467 !default;
$colorGray700: #344054 !default;
$colorGray800: #1D2939 !default;
$colorGray900: #101828 !default;
$colorGrayDisabled: $white !default;

$colorPrimary25: #F6FEFD !default;
$colorPrimary50: #E4F7F4 !default;
$colorPrimary100: #C9F5EF !default;
$colorPrimary200: #ACE7DF !default;
$colorPrimary300: #82D1C5 !default;
$colorPrimary400: #66BCB4 !default;
$colorPrimary500: #45B0AE !default;
$colorPrimary600: #0A92A4 !default;
$colorPrimary700: #0E7F90 !default;
$colorPrimary800: #155B75 !default;
$colorPrimary900: #164C63 !default;
$colorPrimaryDisabled:#A5D6DF !default;


$colorError25: #FFFBFA !default;
$colorError50: #FEF3F2 !default;
$colorError100: #FEE4E2 !default;
$colorError200: #FECDCA !default;
$colorError300: #FDA29B !default;
$colorError400: #F97066 !default;
$colorError500: #F04438 !default;
$colorError600: #D92D20 !default;
$colorError700: #B42318 !default;
$colorError800: #912018 !default;
$colorError900: #7A271A !default;

$colorWarning25: #FFFCF5 !default;
$colorWarning50: #FFFAEB !default;
$colorWarning100: #FEF0C7 !default;
$colorWarning200: #FEDF89 !default;
$colorWarning300: #FEC84B !default;
$colorWarning400: #FDB022 !default;
$colorWarning500: #F79009 !default;
$colorWarning600: #DC6803 !default;
$colorWarning700: #B54708 !default;
$colorWarning800: #93370D !default;
$colorWarning900: #7A2E0E !default;

$colorSuccess25: #F6FEF9 !default;
$colorSuccess50: #ECFDF3 !default;
$colorSuccess100: #D1FADF !default;
$colorSuccess200: #A6F4C5 !default;
$colorSuccess300: #6CE9A6 !default;
$colorSuccess400: #32D583 !default;
$colorSuccess500: #12B76A !default;
$colorSuccess600: #039855 !default;
$colorSuccess700: #027A48 !default;
$colorSuccess800: #05603A !default;
$colorSuccess900: #054F31 !default;


/******* Secondary Colors from UntitledUI *******/

$colorGrayBlue25 : #FCFCFD !default;
$colorGrayBlue50 : #F8F9FC !default;
$colorGrayBlue100 : #EAECF5 !default;
$colorGrayBlue200 : #D5D9EB !default;
$colorGrayBlue300 : #B3B8DB !default;
$colorGrayBlue400 : #717BBC !default;
$colorGrayBlue500 : #4E5BA6 !default;
$colorGrayBlue600 : #3E4784 !default;
$colorGrayBlue700 : #363F72 !default;
$colorGrayBlue800 : #293056 !default;
$colorGrayBlue900 : #101323 !default;

$colorGrayCool25 : #FCFCFD !default;
$colorGrayCool50 : #F9F9FB !default;
$colorGrayCool100 : #EFF1F5 !default;
$colorGrayCool200 : #DCDFEA !default;
$colorGrayCool300 : #B9C0D4 !default;
$colorGrayCool400 : #7D89B0 !default;
$colorGrayCool500 : #5D6B98 !default;
$colorGrayCool600 : #4A5578 !default;
$colorGrayCool700 : #404968 !default;
$colorGrayCool800 : #30374F !default;
$colorGrayCool900 : #111322 !default;

$colorGrayModern25 : #FCFCFD !default;
$colorGrayModern50 : #F8FAFC !default;
$colorGrayModern100 : #EEF2F6 !default;
$colorGrayModern200 : #E3E8EF !default;
$colorGrayModern300 : #CDD5DF !default;
$colorGrayModern400 : #9AA4B2 !default;
$colorGrayModern500 : #697586 !default;
$colorGrayModern600 : #4B5565 !default;
$colorGrayModern700 : #364152 !default;
$colorGrayModern800 : #202939 !default;
$colorGrayModern900 : #121926 !default;

$colorGrayNeutral25 : #FCFCFD !default;
$colorGrayNeutral50 : #F9FAFB !default;
$colorGrayNeutral100 : #F3F4F6 !default;
$colorGrayNeutral200 : #E5E7EB !default;
$colorGrayNeutral300 : #D2D6DB !default;
$colorGrayNeutral400 : #9DA4AE !default;
$colorGrayNeutral500 : #6C737F !default;
$colorGrayNeutral600 : #4D5761 !default;
$colorGrayNeutral700 : #384250 !default;
$colorGrayNeutral800 : #1F2A37 !default;
$colorGrayNeutral900 : #111927 !default;

$colorGrayIron25 : #FCFCFC !default;
$colorGrayIron50 : #FAFAFA !default;
$colorGrayIron100 : #F4F4F5 !default;
$colorGrayIron200 : #E4E4E7 !default;
$colorGrayIron300 : #D1D1D6 !default;
$colorGrayIron400 : #A0A0AB !default;
$colorGrayIron500 : #70707B !default;
$colorGrayIron600 : #51525C !default;
$colorGrayIron700 : #3F3F46 !default;
$colorGrayIron800 : #26272B !default;
$colorGrayIron900 : #18181B !default;

$colorGrayTrue25 : #FCFCFC !default;
$colorGrayTrue50 : #FAFAFA !default;
$colorGrayTrue100 : #F5F5F5 !default;
$colorGrayTrue200 : #E5E5E5 !default;
$colorGrayTrue300 : #D6D6D6 !default;
$colorGrayTrue400 : #A3A3A3 !default;
$colorGrayTrue500 : #737373 !default;
$colorGrayTrue600 : #525252 !default;
$colorGrayTrue700 : #424242 !default;
$colorGrayTrue800 : #292929 !default;
$colorGrayTrue900 : #141414 !default;

$colorGrayWarm25 : #FDFDFC !default;
$colorGrayWarm50 : #FAFAF9 !default;
$colorGrayWarm100 : #F5F5F4 !default;
$colorGrayWarm200 : #E7E5E4 !default;
$colorGrayWarm300 : #D7D3D0 !default;
$colorGrayWarm400 : #A9A29D !default;
$colorGrayWarm500 : #79716B !default;
$colorGrayWarm600 : #57534E !default;
$colorGrayWarm700 : #44403C !default;
$colorGrayWarm800 : #292524 !default;
$colorGrayWarm900 : #1C1917 !default;

$colorMoss25 : #FAFDF7 !default;
$colorMoss50 : #F5FBEE !default;
$colorMoss100 : #E6F4D7 !default;
$colorMoss200 : #CEEAB0 !default;
$colorMoss300 : #ACDC79 !default;
$colorMoss400 : #86CB3C !default;
$colorMoss500 : #669F2A !default;
$colorMoss600 : #4F7A21 !default;
$colorMoss700 : #3F621A !default;
$colorMoss800 : #335015 !default;
$colorMoss900 : #2B4212 !default;

$colorGreenLight25 : #FAFEF5 !default;
$colorGreenLight50 : #F3FEE7 !default;
$colorGreenLight100 : #E3FBCC !default;
$colorGreenLight200 : #D0F8AB !default;
$colorGreenLight300 : #A6EF67 !default;
$colorGreenLight400 : #85E13A !default;
$colorGreenLight500 : #66C61C !default;
$colorGreenLight600 : #4CA30D !default;
$colorGreenLight700 : #3B7C0F !default;
$colorGreenLight800 : #326212 !default;
$colorGreenLight900 : #2B5314 !default;

$colorGreen25 : #F6FEF9 !default;
$colorGreen50 : #EDFCF2 !default;
$colorGreen100 : #D3F8DF !default;
$colorGreen200 : #AAF0C4 !default;
$colorGreen300 : #73E2A3 !default;
$colorGreen400 : #3CCB7F !default;
$colorGreen500 : #16B364 !default;
$colorGreen600 : #099250 !default;
$colorGreen700 : #087443 !default;
$colorGreen800 : #095C37 !default;
$colorGreen900 : #084C2E !default;

$colorTeal25 : #F6FEFC !default;
$colorTeal50 : #F0FDF9 !default;
$colorTeal100 : #CCFBEF !default;
$colorTeal200 : #99F6E0 !default;
$colorTeal300 : #5FE9D0 !default;
$colorTeal400 : #2ED3B7 !default;
$colorTeal500 : #15B79E !default;
$colorTeal600 : #0E9384 !default;
$colorTeal700 : #107569 !default;
$colorTeal800 : #125D56 !default;
$colorTeal900 : #134E48 !default;

$colorCyan25 : #F5FEFF !default;
$colorCyan50 : #ECFDFF !default;
$colorCyan100 : #CFF9FE !default;
$colorCyan200 : #A5F0FC !default;
$colorCyan300 : #67E3F9 !default;
$colorCyan400 : #22CCEE !default;
$colorCyan500 : #06AED4 !default;
$colorCyan600 : #088AB2 !default;
$colorCyan700 : #0E7090 !default;
$colorCyan800 : #155B75 !default;
$colorCyan900 : #164C63 !default;

$colorBlueLight25 : #F5FBFF !default;
$colorBlueLight50 : #F0F9FF !default;
$colorBlueLight100 : #E0F2FE !default;
$colorBlueLight200 : #B9E6FE !default;
$colorBlueLight300 : #7CD4FD !default;
$colorBlueLight400 : #36BFFA !default;
$colorBlueLight500 : #0BA5EC !default;
$colorBlueLight600 : #0086C9 !default;
$colorBlueLight700 : #026AA2 !default;
$colorBlueLight800 : #065986 !default;
$colorBlueLight900 : #0B4A6F !default;

$colorBlue25 : #F5FAFF !default;
$colorBlue50 : #EFF8FF !default;
$colorBlue100 : #D1E9FF !default;
$colorBlue200 : #B2DDFF !default;
$colorBlue300 : #84CAFF !default;
$colorBlue400 : #53B1FD !default;
$colorBlue500 : #2E90FA !default;
$colorBlue600 : #1570EF !default;
$colorBlue700 : #175CD3 !default;
$colorBlue800 : #1849A9 !default;
$colorBlue900 : #194185 !default;

$colorBlueDark25 : #F5F8FF !default;
$colorBlueDark50 : #EFF4FF !default;
$colorBlueDark100 : #D1E0FF !default;
$colorBlueDark200 : #B2CCFF !default;
$colorBlueDark300 : #84ADFF !default;
$colorBlueDark400 : #528BFF !default;
$colorBlueDark500 : #2970FF !default;
$colorBlueDark600 : #155EEF !default;
$colorBlueDark700 : #004EEB !default;
$colorBlueDark800 : #0040C1 !default;
$colorBlueDark900 : #00359E !default;

$colorIndigo25 : #F5F8FF !default;
$colorIndigo50 : #EEF4FF !default;
$colorIndigo100 : #E0EAFF !default;
$colorIndigo200 : #C7D7FE !default;
$colorIndigo300 : #A4BCFD !default;
$colorIndigo400 : #8098F9 !default;
$colorIndigo500 : #6172F3 !default;
$colorIndigo600 : #444CE7 !default;
$colorIndigo700 : #3538CD !default;
$colorIndigo800 : #2D31A6 !default;
$colorIndigo900 : #2D3282 !default;

$colorViolet25 : #FBFAFF !default;
$colorViolet50 : #F5F3FF !default;
$colorViolet100 : #ECE9FE !default;
$colorViolet200 : #DDD6FE !default;
$colorViolet300 : #C3B5FD !default;
$colorViolet400 : #A48AFB !default;
$colorViolet500 : #875BF7 !default;
$colorViolet600 : #7839EF !default;
$colorViolet700 : #6927DA !default;
$colorViolet800 : #5720B7 !default;
$colorViolet900 : #491C96 !default;

$colorPurple25 : #FAFAFF !default;
$colorPurple50 : #F4F3FF !default;
$colorPurple100 : #EBE9FE !default;
$colorPurple200 : #D9D6FE !default;
$colorPurple300 : #BDB4FE !default;
$colorPurple400 : #9B8AFB !default;
$colorPurple500 : #7A5AF8 !default;
$colorPurple600 : #6938EF !default;
$colorPurple700 : #5925DC !default;
$colorPurple800 : #4A1FB8 !default;
$colorPurple900 : #3E1C96 !default;

$colorFuchsia25 : #FEFAFF !default;
$colorFuchsia50 : #FDF4FF !default;
$colorFuchsia100 : #FBE8FF !default;
$colorFuchsia200 : #F6D0FE !default;
$colorFuchsia300 : #EEAAFD !default;
$colorFuchsia400 : #E478FA !default;
$colorFuchsia500 : #D444F1 !default;
$colorFuchsia600 : #BA24D5 !default;
$colorFuchsia700 : #9F1AB1 !default;
$colorFuchsia800 : #821890 !default;
$colorFuchsia900 : #6F1877 !default;

$colorPink25 : #FEF6FB !default;
$colorPink50 : #FDF2FA !default;
$colorPink100 : #FCE7F6 !default;
$colorPink200 : #FCCEEE !default;
$colorPink300 : #FAA7E0 !default;
$colorPink400 : #F670C7 !default;
$colorPink500 : #EE46BC !default;
$colorPink600 : #DD2590 !default;
$colorPink700 : #C11574 !default;
$colorPink800 : #9E165F !default;
$colorPink900 : #851651 !default;

$colorRose25 : #FFF5F6 !default;
$colorRose50 : #FFF1F3 !default;
$colorRose100 : #FFE4E8 !default;
$colorRose200 : #FECDD6 !default;
$colorRose300 : #FEA3B4 !default;
$colorRose400 : #FD6F8E !default;
$colorRose500 : #F63D68 !default;
$colorRose600 : #E31B54 !default;
$colorRose700 : #C01048 !default;
$colorRose800 : #A11043 !default;
$colorRose900 : #89123E !default;

$colorOrangeDark25 : #FFF9F5 !default;
$colorOrangeDark50 : #FFF4ED !default;
$colorOrangeDark100 : #FFE6D5 !default;
$colorOrangeDark200 : #FFD6AE !default;
$colorOrangeDark300 : #FF9C66 !default;
$colorOrangeDark400 : #FF692E !default;
$colorOrangeDark500 : #FF4405 !default;
$colorOrangeDark600 : #E62E05 !default;
$colorOrangeDark700 : #BC1B06 !default;
$colorOrangeDark800 : #97180C !default;
$colorOrangeDark900 : #771A0D !default;

$colorOrange25 : #FEFAF5 !default;
$colorOrange50 : #FEF6EE !default;
$colorOrange100 : #FDEAD7 !default;
$colorOrange200 : #F9DBAF !default;
$colorOrange300 : #F7B27A !default;
$colorOrange400 : #F38744 !default;
$colorOrange500 : #EF6820 !default;
$colorOrange600 : #E04F16 !default;
$colorOrange700 : #B93815 !default;
$colorOrange800 : #932F19 !default;
$colorOrange900 : #772917 !default;

$colorYellow25 : #FEFDF0 !default;
$colorYellow50 : #FEFBE8 !default;
$colorYellow100 : #FEF7C3 !default;
$colorYellow200 : #FEEE95 !default;
$colorYellow300 : #FDE272 !default;
$colorYellow400 : #FAC515 !default;
$colorYellow500 : #EAAA08 !default;
$colorYellow600 : #CA8504 !default;
$colorYellow700 : #A15C07 !default;
$colorYellow800 : #854A0E !default;
$colorYellow900 : #713B12 !default;



/******** Enrichment ED **********/


$level1TextColor: $colorGray900 !default;
$level2TextColor: $colorGray600 !default;
$level3TextColor: $colorGray400 !default;
$level4TextColor: $colorGray700 !default;

/*
Variables replacement :
$lightGrey => $colorGray200 !default;
$light2Grey => $colorGray300 !default;
$light3Grey => $colorGray50 !default;
$veryLightGrey => $colorGray25 !default;
$midGrey => $colorGray500 !default;
*/


//aec theme

$colorAec25 : #EAEDF3 !default;
$colorAec50 : #D5DCE7 !default;
$colorAec100 : #C0CADB !default;
$colorAec200 : #ABB9CF !default;
$colorAec300 : #96A7C3 !default;
$colorAec400 : #8196B6 !default;
$colorAec500 : #6C84AA !default;
$colorAec600 : #57739E !default;
$colorAec700 : #426192 !default;
$colorAec800 : #2D5086 !default;
$colorAec900 : #183E7A !default;
$colorAecDisabled : #ABB9CF !default;