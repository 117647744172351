@import '../scss/variables.scss';

h1 {
    color: $colorPrimary800;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 12px;
}

a {
    font-weight: 600;
    color: $colorPrimary700;
    cursor: pointer;
    text-decoration: none;

    &:hover {
        color: darken($colorPrimary700, 10%);
    }
}

label {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color: $colorGray700;

    &.required::after {
        content: ' *';
        color: $colorWarning400;
    }

    &.required-alt::after {
        content: ' *';
        color: $colorPrimary500;
    }
}
