@import '.../../../../../../scss/colors.scss';

.legend {
    font-family: Barlow;
    padding: 6px 8px;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    color: $level1TextColor;

    .legend-title {
        font-size: 14px;

        &:not(:first-child) {
            margin-top: 10px;
        }
    }

    .legend-item {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        margin-bottom: 4px;

        i {
            width: 26px;
            height: 18px;
            opacity: 0.8;
            border: 1px solid black;
        }
    }
}
