.header-content {
    h1 {
        color: $colorAec800 !important;
    }
}

.btn-forget-pwd {
    color: $colorAec700 !important;
}

.layer-title {
    color: $colorAec800 !important;
}

.page-header {
    h1 {
        color: $colorAec800 !important;
    }
}

.btn-connect {
    color: $colorAec700 !important;
}

.email-info {
    .link {
        color: $colorAec600 !important;
    }
}

.swal2-popup {
    font-family: Barlow !important;

    h2.swal2-title.swal-title.title-delete {
        color: $colorError700 !important;
    }

    h2.swal2-title.swal-title {
        color: $colorAec800 !important;
    }
}
