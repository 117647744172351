@import '../scss/variables.scss';

.page-content {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .page-header {
        padding: 0 32px;
        display: flex;
        align-items: end;
        gap: 16px;

        h1 {
            margin-bottom: 0;
            line-height: 32px;
            font-size: 24px;
            font-weight: 700;
            color: $colorPrimary800;
        }

        .page-description {
            font-size: 16px;
            font-weight: 400;
            color: $colorGray600;
        }
    }

    .page-body {
        padding: 0 32px;

        &:last-child {
            padding-bottom: 32px;
        }
    }
}
