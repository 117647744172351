.ed-button.primary {
    background: $colorAec600 !important;
    border-color: $colorAec600 !important;
}
.header .icon-box.info {
    color: $colorAec600 !important;
    background-color: $colorAec25 !important;
}

.swal2-popup{
    div.swal2-actions.swal-actions {
        button.swal2-confirm.swal-confirm-button.button-delete {
            background: $colorError600 !important;
            border-color: $colorError600 !important;
        }
    }

    h2.swal2-title.swal-title.button-delete {
        color: $colorError700 !important;
    }

    div.swal2-actions.swal-actions {
        button.swal2-confirm.swal-confirm-button {
            background: $colorAec600 !important;
            border-color: $colorAec600 !important;
        }
    }
}

ed-button button.ed-button:disabled, 
ed-button button.ed-button[disabled] {
    background: $colorAecDisabled !important;
    border-color: $colorAecDisabled !important;
}

.cdk-overlay-pane {
    font-family: Barlow;
}

.modal-content-header {
    h1 {
    color: $colorAec800 !important;
    }
}