@import './variables.scss';

// Scrollbar
$scrollBarColor: $colorGray200 $white;
$scrollBarBackground: $white;
$scrollBarThumbBackground: $colorGray200;

/* Firefox */
* {
    scrollbar-width: auto;
    scrollbar-color: $scrollBarColor;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 16px;
}

*::-webkit-scrollbar-track {
    background: $scrollBarBackground;
    border-radius: 12px;
    border: 4px solid transparent;
    border-left: 0 solid transparent;
    background-clip: content-box;
}

*::-webkit-scrollbar-thumb {
    background-color: $scrollBarThumbBackground;
    border-radius: 12px;
    border: 4px solid transparent;
    border-left: 0 solid transparent;
    background-clip: content-box;
}
