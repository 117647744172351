@import 'variables';

.skeleton {
    background: #eeeeee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    background-size: 200% 100%;
    animation: 1.2s shine linear infinite;
}
@keyframes shine {
    to {
        background-position-x: -200%;
    }
}

.ed-input {
    padding: 12px 14px;
    background: white;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border: 1px solid $colorGray300;
    border-radius: 8px;
    color: $level1TextColor;
    font-size: 14px;
    font-weight: 400;
    box-sizing: border-box;
    width: 100%;

    &::placeholder {
        color: $level3TextColor;
    }

    &:disabled {
        background: $colorGray50;
    }

    &:focus,
    &:focus-visible {
        border: 1px solid $inputFocusedColor;
        outline: none;
    }

    &.ng-invalid.ng-dirty {
        border: 1px solid $inputErrorColor;

        &:focus,
        &:focus-visible {
            border: 1px solid $inputErrorColor;
            outline: none;
        }
    }
}

// header dropdown
.header-dropdown {
    &.mat-mdc-menu-panel {
        min-width: 240px !important;
        margin-top: 11px;
        margin-right: 3px;
        border-radius: 10px !important;
        box-shadow: 0px 3px 10px 2px rgba(16, 24, 40, 0.0784) !important;
    }

    .mat-mdc-menu-content {
        padding: 0 !important;
        font-family: Barlow !important;

        button.mat-mdc-menu-item {
            &:first-child {
                border-bottom: 1px solid $colorGray200;
            }

            &:last-child {
                border-top: 1px solid $colorGray200;
            }
        }

        .mat-mdc-menu-item-text {
            display: flex;
            align-items: center;
        }
    }
}

// Map Dropdown
.map-dropdown .mat-mdc-menu-content button.mat-mdc-menu-item {
    min-height: 30px;
    color: $colorPrimary800;

    .mat-mdc-menu-item-text {
        font-size: 12px;
        font-weight: 600;
    }
}

.layer-actions {
    display: flex;
    margin-top: $largeLayerTitleFontSize;
    margin-bottom: $layerDescMarginBottom;
    width: 100%;
    justify-content: center;

    ed-button {
        min-width: 250px;
    }

    ed-button:not(:last-child) {
        margin-right: 10px;
    }
}

.icon-x-close:hover {
    transform: none;
    background: rgba(0, 0, 0, 0);
    color: #f27474;
}

.layer-background .layer-wrapper .layer-quit {
    i {
        color: #667085 !important;
    }

    i:hover {
        transform: none !important;
        background: rgba(0, 0, 0, 0) !important;
        color: #f27474 !important;
    }
}

//Remove SVG Download options on apexCharts
.apexcharts-menu-item.exportSVG {
    display: none;
}
