/* You can add global styles to this file, and also import other style files */

//// Uncomment the following if @ed---interne/ng-uui-components/assets/scss/components is not imported
// @use '@angular/material' as mat;
// @include mat.core();

// // Available color palettes: https://material.io/design/color/
// $front-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
// $front-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);
// $front-warn: mat.m2-define-palette(mat.$m2-red-palette);
// $front-theme: mat.m2-define-light-theme(
//     (
//         color: (
//             primary: $front-primary,
//             accent: $front-accent,
//             warn: $front-warn,
//         ),
//     )
// );
// @include mat.all-component-themes($front-theme);

/* Importing Bootstrap SCSS file. */

@import 'node_modules/bootstrap/scss/bootstrap';
@import './scss/variables.scss';
@import './scss/typography.scss';
@import './scss/mixins';
@import './scss/swal';
@import './scss/components.scss';
@import './scss/theme/button.scss';
@import './scss/theme/text.scss';
@import '@ed---interne/ng-uui-components/assets/scss/components';
@import 'tippy.js/dist/tippy.css';

@import './scss/page.scss';
@import './scss/modal.scss';
@import './scss/scroll.scss';
@import './scss/tabs.scss';
@import './scss/text.scss';

app-root {
    height: 100dvh;
    font-family: Barlow;
}

.sticky-bottom-buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    position: absolute;
    background: rgba(255, 255, 255, 0.75);
    padding: 8px 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 910;
}

.tippy-box.ed-custom-tippy {
    background-color: $colorGray900;
    border-radius: 8px;
    color: white;
    padding: 10px 12px 10px 12px;
    font-size: 12px;
    line-height: 18px;

    &.left-aligned {
        .tippy-content {
            align-items: start;
        }
    }

    .tippy-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 4px;
        padding: 0;

        ul {
            margin: 0;
            padding: 0 0 0 24px;
        }
    }

    .tippy-arrow {
        color: $colorGray900;
    }
}

.width-500 {
    width: 500px !important;
}
.textfield-message {
    margin-top: 0px !important;
}
